<template>
  <div class="base-form">
    <page-form
      ref="baseFormRef"
      :baseFormConfig="baseFormConfig"
      @handleNewClick="handleSubmitClick"
      :pageTitle="pageTitle"
      :pageInfo="pageInfo"
      :isResetFields="false"
    ></page-form>
  </div>
</template>

<script setup>
import pageForm from '@/components/PageForm/page-form.vue'
import { baseFormConfig } from './config/card.edit.js'
import { useStore } from 'vuex'
import { ref, getCurrentInstance } from 'vue'
import i18n from '@/i18n'
import _ from 'lodash' // 导入loadsh插件
import { useRoute, useRouter } from 'vue-router'

// 页面标题
const pageTitle = ref('')
const { t } = i18n.global
const routes = useRoute()
const router = useRouter()

pageTitle.value = t('general.router-card-edit')
const id = routes.params.id

const store = useStore()
const pageInfo = ref({})
const { appContext } = getCurrentInstance()
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit

const getCurrentPageInfo = async () => {
  // 获取当前页面的初始化值
  const cardInfo = await store.dispatch('param/getCardListByID', { id })
  pageInfo.value = cardInfo
}

const handleSubmitClick = async (data) => {
  const form = _.cloneDeep(data)
  form.id = id
  handleMessageCommit('param/handleEditCardAction', form).then(() => {
    router.push('/parameter/card')
  })
}

getCurrentPageInfo()
</script>
